import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
import { requests } from '@helpers/requests'
// components
import { Form, Divider } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel'
import ModalSubmit from '@components/buttons/ModalSubmit'

const FuelCardForm = ({ record, setData, setTotal, onClose, setHeader, setSize }) => {
    const { t } = useTranslation()

    const [isProcessing, setIsProcessing] = useState(false)

    const [errors, setErrors] = useState(null)

    const [form, setForm] = useState({
        card_number: record?.card_number || '',
        expiration_date: record?.expiration_date || '',
        provider: record?.provider || '',
        cost_center: record?.cost_center?.id || '',
    })

    useEffect(() => {
        if (record?.id) {
            setHeader(t('edit_card'))
        }
        // eslint-disable-next-line
    }, [])

    const isFormValid = () => {
        if (form.card_number === '') return false

        return true
    }

    const handleSubmit = async () => {
        setIsProcessing(true)
        setErrors(null)
        const formData = {
            is_active: true,
            card_number: form.card_number,
            expiration_date: form?.expiration_date || null,
            provider: form.provider,
            cost_center: form?.cost_center || null,
        }

        if (record?.id) {
            // update
            const request = await requests.patch(API.VEHICLES + 'fuel_cards/' + record?.id + '/', formData)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 200) {
                setData((prev) =>
                    prev.map((item) => {
                        if (item.id === request.response.id) {
                            item = request.response
                        }
                        return item
                    })
                )
                onClose()
            }
        } else {
            // create
            const request = await requests.post(API.VEHICLES + 'fuel_cards/', formData)
            if (request.status === 400) setErrors(request.response)
            if (request.status === 201) {
                setTotal((prev) => prev + 1)
                setData((prev) => [request.response, ...prev])
                onClose()
            }
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group widths="equal">
                <SuperField
                    as="input"
                    autoFocus
                    required
                    error={errors?.card_number?.[0] || false}
                    label={t('card_number')}
                    value={form.card_number}
                    onChange={(e, { value }) =>
                        setForm({
                            ...form,
                            card_number: value,
                        })
                    }
                />
                <SuperField
                    as="datepicker"
                    clearable
                    error={errors?.expiration_date?.[0] || false}
                    label={t('expiration_date')}
                    value={form.expiration_date}
                    onChange={(e, { value }) =>
                        setForm({
                            ...form,
                            expiration_date: value,
                        })
                    }
                />
            </Form.Group>
            <SuperField
                as="input"
                label={t('provider')}
                value={form.provider}
                onChange={(e, { value }) =>
                    setForm({
                        ...form,
                        provider: value,
                    })
                }
            />
            <Form.Group widths="equal">
                <SuperField
                    as="choice-select"
                    search
                    label={t('cost_center')}
                    endpoint={API.COST_CENTERS}
                    text="title"
                    value={form.cost_center}
                    onChange={(e, { value }) =>
                        setForm({
                            ...form,
                            cost_center: value,
                        })
                    }
                    initialOptions={{
                        attribute: 'title',
                        source: record?.cost_center,
                    }}
                />
            </Form.Group>
            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit loading={isProcessing} disabled={!isFormValid() || isProcessing} />
            </Form.Field>
        </Form>
    )
}

export default FuelCardForm
