import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
// store
import { API } from '@store/config'
// components
import Icon from '@components/Icon'
import { Form } from 'semantic-ui-react'
import ListView from '@components/ListView'
import DatePresets from '@components/DatePresets'
import SuperField from '@components/forms/SuperField'
import FuelCardForm from '../components/FuelCardForm'
import FuelCardTransactions from '../components/FuelCardTransactions'

const FuelCardsList = () => {
    const { t } = useTranslation()
    const dateFormat = useSelector((state) => state.date_format)

    return (
        <ListView
            as="table"
            allowSearch
            actionsCellWidth="2"
            endpoint={API.VEHICLES + 'fuel_cards/'}
            initialFilters={{
                card_number: '',
                cost_center: '',
                expiration_date_after: '',
                expiration_date_before: '',
                is_active: true,
                provider: '',
            }}
            renderFilterFields={(filters, setFilters) => (
                <>
                    <Form.Group widths="equal">
                        <SuperField
                            as="datepicker"
                            size="small"
                            label={t('date_from')}
                            value={filters.expiration_date_after}
                            style={{ height: '2.75rem' }}
                            onChange={(e, { value }) => setFilters({ ...filters, expiration_date_after: value })}
                        />
                        <SuperField
                            as="datepicker"
                            size="small"
                            label={t('date_to')}
                            value={filters.expiration_date_before}
                            style={{ height: '2.75rem' }}
                            onChange={(e, { value }) => setFilters({ ...filters, expiration_date_before: value })}
                        />
                    </Form.Group>
                    <DatePresets
                        from={filters.expiration_date_after}
                        to={filters.expiration_date_before}
                        onSelection={(firstMonthDay, lastMonthDay, year) => {
                            setFilters((prev) => ({
                                ...prev,
                                expiration_date_after: firstMonthDay,
                                expiration_date_before: lastMonthDay,
                            }))
                        }}
                    />
                    <SuperField
                        as="input"
                        label={t('provider')}
                        value={filters.provider}
                        onChange={(e, { value }) =>
                            setFilters({
                                ...filters,
                                provider: value,
                            })
                        }
                    />
                    <SuperField
                        as="choice-select"
                        search
                        label={t('cost_center')}
                        endpoint={API.COST_CENTERS}
                        additionalFilters={'&query={id, title}'}
                        text="title"
                        value={filters.cost_center}
                        onChange={(e, { value }) =>
                            setFilters({
                                ...filters,
                                cost_center: value,
                            })
                        }
                    />
                </>
            )}
            listAdditionActions={() => [
                {
                    as: 'filter',
                    index: 0,
                    name: t('active'),
                    filters: {
                        is_active: true,
                    },
                },
                {
                    as: 'filter',
                    index: 1,
                    name: t('inactive'),
                    filters: {
                        is_active: false,
                    },
                },
                {
                    as: 'filter',
                    index: 2,
                    name: t('all'),
                    filters: {
                        is_active: null,
                    },
                },
            ]}
            tableHeaders={[
                { title: t('card_number') },
                { title: t('expiration_date') },
                { title: t('cost_center'), orderBy: 'cost_center__title' },
                //{ title: t('vehicle') },
                { title: t('provider') },
                { title: t('status') },
            ]}
            renderCells={(card, setData, setTotal) => [
                { content: card.card_number },
                {
                    content: card?.expiration_date
                        ? moment(card?.expiration_date).format(dateFormat)
                        : t('indefinite_period'),
                },
                { content: card?.cost_center?.title },
                //{ content: card.vehicle },
                { content: card?.provider },
                {
                    content: (
                        <div>
                            {card.is_active ? (
                                <Icon name="ellipse" style={{ color: 'var(--success)', marginRight: '0.5rem' }} />
                            ) : (
                                <Icon name="ellipse" style={{ color: 'var(--danger)', marginRight: '0.5rem' }} />
                            )}
                            {card.is_active ? t('active') : t('inactive')}
                        </div>
                    ),
                },
            ]}
            actions={[
                {
                    as: 'modal',
                    type: 'add',
                    name: t('add_card'),
                    modalSize: 'tiny',
                    modal: <FuelCardForm />,
                    permissions: ['vehicles.c_manage_all_vehicles'],
                },
                {
                    as: 'modal',
                    type: 'custom',
                    icon: 'list-outline',
                    name: t('transactions'),
                    modalSize: 'large',
                    permissions: ['integrations.c_view_external_car_costs'],
                    modal: <FuelCardTransactions />,
                },
                {
                    as: 'modal',
                    type: 'edit',
                    name: t('edit'),
                    modalSize: 'tiny',
                    permissions: ['vehicles.c_manage_all_vehicles'],
                    modal: <FuelCardForm />,
                },
                {
                    name: t('delete'),
                    type: 'delete',
                    as: 'delete',
                    text: t('delete_card_message'),
                    permissions: ['vehicles.c_manage_all_vehicles'],
                },
            ]}
        />
    )
}

export default FuelCardsList
